// ----------------------------------------------------//
//          dailyWebsites
// ----------------------------------------------------//
const dailyWebsites = [{
        "webimg": "/assets/images/sites/icons8-google-48.png",
        "websitename": "Google",
        "weburl": "https://www.google.com.hk/",
        "status": true
    },
    {
        "webimg": "/assets/images/sites/youtube_favicon_96x96.png",
        "websitename": "Youtube",
        "weburl": "https://www.youtube.com/?gl=HK",
        "status": true
    },
    {
        "webimg": "/assets/images/sites/icon-raindrop.png",
        "websitename": "Raindrop",
        "weburl": "https://app.raindrop.io/",
        "status": true
    },
    {
        "webimg": "/assets/images/sites/icons8-facebook-48.png",
        "websitename": "Facebook",
        "weburl": "https://www.facebook.com/",
        "status": true
    },
    {
        "webimg": "/assets/images/sites/icons8-gmail-48.png",
        "websitename": "Gmail",
        "weburl": "https://mail.google.com/mail/u/1/?tab=wm#inbox",
        "status": true
    },
    {
        "webimg": "/assets/images/sites/icon-instagram.png",
        "websitename": "Instagram",
        "weburl": "https://www.instagram.com/",
        "status": true
    },
    {
        "webimg": "/assets/images/sites/icons8-twitter-96.png",
        "websitename": "Twitter",
        "weburl": "https://twitter.com/",
        "status": true
    },
    {
        "webimg": "/assets/images/sites/premierleague.png",
        "websitename": "Premier League",
        "weburl": "https://www.premierleague.com/",
        "status": true
    },
    {
        "webimg": "/assets/images/sites/icon-gtranslate-favicon.png",
        "websitename": "G. Translate",
        "weburl": "https://translate.google.com/?source=gtx",
        "status": true
    },
    {
        "webimg": "/assets/images/sites/Icon-Poe-Favicon.png",
        "websitename": "POE",
        "weburl": "https://poe.com/",
        "status": true
    },
    {
        "webimg": "/assets/images/sites/vercel-60x60.png",
        "websitename": "Vercel",
        "weburl": "https://vercel.com/dashboard",
        "status": true
    },
    {
        "webimg": "/assets/images/sites/icons8-dropbox-48.png",
        "websitename": "Dropbox",
        "weburl": "https://www.dropbox.com/",
        "status": true
    },
    {
        "webimg": "/assets/images/sites/icons8-google-maps-96.png",
        "websitename": "Google Map",
        "weburl": "https://www.google.com.hk/maps",
        "status": true
    },
    {
        "webimg": "/assets/images/sites/discord-mark-blue.png",
        "websitename": "Discord",
        "weburl": "https://discord.com/channels/@me",
        "status": true
    },
    {
        "webimg": "/assets/images/sites/icons8-ship-100.png",
        "websitename": "Midjourney",
        "weburl": "https://www.midjourney.com/",
        "status": true
    },
    {
        "webimg": "/assets/images/sites/icons8-grok-96.png",
        "websitename": "Grok ",
        "weburl": "https://grok.com/",
        "status": true
    },

];

// ----------------------------------------------------//
//          magazineWebsites
// ----------------------------------------------------//
const magazineWebsites = [{
        "webimg": "/assets/images/sites/icons8-netflix-482.png",
        "websitename": "Netflix",
        "weburl": "https://www.netflix.com/browse",
        "status": true
    },
    {
        "webimg": "/assets/images/sites/icons8-disney-96.png",
        "websitename": "Disney+",
        "weburl": "https://www.disneyplus.com/zh-hk/home",
        "status": true
    },
    {
        "webimg": "/assets/images/sites/nav_logo.svg",
        "websitename": "NowTv",
        "weburl": "https://nowplayer.now.com/",
        "status": true
    },
    {
        "webimg": "/assets/images/sites/icon-spotify.png",
        "websitename": "Spotify",
        "weburl": "https://open.spotify.com/browse/featured",
        "status": true
    },
    {
        "webimg": "/assets/images/sites/icons8-pinterest-48.png",
        "websitename": "Pinterest",
        "weburl": "https://www.pinterest.com/",
        "status": true
    },
    {
        "webimg": "/assets/images/sites/icons8-linkedin-48.png",
        "websitename": "Linkedin",
        "weburl": "https://www.linkedin.com/feed/",
        "status": true
    },
    {
        "webimg": "/assets/images/sites/fotmob-logo.svg",
        "websitename": "FotMob",
        "weburl": "https://www.fotmob.com/",
        "status": true
    },
    {
        "webimg": "/assets/images/sites/webimg",
        "websitename": "Medium",
        "weburl": "https://medium.com/",
        "status": false
    },
    {
        "webimg": "/assets/images/sites/icons8-amazon-48.png",
        "websitename": "Amazon",
        "weburl": "https://www.amazon.com/",
        "status": true
    },
    {
        "webimg": "/assets/images/sites/icons8-bento-48.png",
        "websitename": "bento.me",
        "weburl": "https://bento.me/tommys",
        "status": true
    },
    {
        "webimg": "/assets/images/sites/icon-linktr.png",
        "websitename": "Linktree",
        "weburl": "https://linktr.ee/tomyswf",
        "status": true
    },
    {
        "webimg": "/assets/images/sites/icon-whatsapp.png",
        "websitename": "Whatsapp",
        "weburl": "https://web.whatsapp.com/",
        "status": true
    },

];



// ----------------------------------------------------//
//          devWebsites
// ----------------------------------------------------//
const devWebsites = [{
        "webimg": "/assets/images/sites/icon-digitalocean.png",
        "websitename": "Digital Ocean",
        "weburl": "https://cloud.digitalocean.com/",
        "status": true
    },
    {
        "webimg": "/assets/images/sites/icons8-unsplash-96.png",
        "websitename": "Unsplash",
        "weburl": "https://unsplash.com/",
        "status": true
    },
    {
        "webimg": "/assets/images/sites/icons8-dribbble-96.png",
        "websitename": "Dribbble",
        "weburl": "https://dribbble.com/",
        "status": true
    },
    {
        "webimg": "/assets/images/sites/icons8-codepen-50.png",
        "websitename": "Codepen",
        "weburl": "https://codepen.io/",
        "status": true
    },
    {
        "webimg": "/assets/images/sites/webimg",
        "websitename": "websitename",
        "weburl": "weburl",
        "status": false
    },
    {
        "webimg": "/assets/images/sites/webimg",
        "websitename": "Github",
        "weburl": "https://github.com/",
        "status": true
    },
    {
        "webimg": "/assets/images/sites/icons8-notion-50.png",
        "websitename": "Notion",
        "weburl": "https://www.notion.so/",
        "status": true
    },
    {
        "webimg": "/assets/images/sites/icon-googleidx.png",
        "websitename": "Google IDX",
        "weburl": "https://idx.google.com/",
        "status": true
    },
];


// ----------------------------------------------------//
//          workWebsites
// ----------------------------------------------------//
const workWebsites = [{
        "webimg": "/assets/images/sites/webimg",
        "websitename": "MS Office",
        "weburl": "https://www.office.com/",
        "status": true
    },
    {
        "webimg": "/assets/images/sites/icon-Jira.png",
        "websitename": "Jira",
        "weburl": "https://elab-aswatson.atlassian.net/secure/Dashboard.jspa",
        "status": true
    },
    {
        "webimg": "/assets/images/sites/icons8-confluence-96.png",
        "websitename": "Confluence",
        "weburl": "https://elab-aswatson.atlassian.net/wiki/spaces/AEA/overview",
        "status": true
    },
    {
        "webimg": "/assets/images/sites/webimg",
        "websitename": "Topdesk",
        "weburl": "https://aswatsongroup.topdesk.net/",
        "status": true
    },
    {
        "webimg": "/assets/images/sites/webimg",
        "websitename": "Watsup",
        "weburl": "https://aswatson.mangoapps.com/ce/pulse/user/overview/index",
        "status": true
    },
    {
        "webimg": "/assets/images/sites/webimg",
        "websitename": "Teams",
        "weburl": "https://teams.microsoft.com/",
        "status": true
    },
    {
        "webimg": "/assets/images/sites/icon-standardnotes.png",
        "websitename": "Standard Notes",
        "weburl": "https://app.standardnotes.com/",
        "status": true
    },
    {
        "webimg": "/assets/images/sites/webimg",
        "websitename": "Github (ASW)",
        "weburl": "https://github.com/ASWG-HK-eLab",
        "status": true
    },
    {
        "webimg": "/assets/images/sites/webimg",
        "websitename": "Skype",
        "weburl": "https://web.skype.com/",
        "status": true
    },

];


export { dailyWebsites, magazineWebsites, workWebsites, devWebsites };